import React, { useEffect, useRef } from 'react';
import './AboutSection.css';
import cat2 from "../images/cat2.png";
import cat3 from "../images/cat3.png";
import cat4 from "../images/cat4.png";
import hand from "../images/hand.png";
import hand2 from "../images/hand2.png";

const AboutSection = () => {
    const leftImageRef = useRef(null);
    const rightImageRef = useRef(null);
  
    useEffect(() => {
      const parallaxEffect = () => {
        const scrolled = window.scrollY;
        const parallaxValue = scrolled * 0.5;
        // Aplica el efecto parallax hacia abajo en ambas imágenes
        leftImageRef.current.style.transform = `translateY(${parallaxValue}px)`;
        rightImageRef.current.style.transform = `translateY(${parallaxValue}px)`;
      };
  
      window.addEventListener('scroll', parallaxEffect);
  
      return () => {
        window.removeEventListener('scroll', parallaxEffect);
      };
    }, []);

    return (
        <section className="about-section">
            <div className="parallax-background"></div>
            <div className="about-border-top"></div>
            <div className="about-content">
            <img ref={leftImageRef} className="left-image" src={hand2} alt="cat2" />
                <div className="about-image-container">
                    <div className="about-image">
                        <img src={cat2} alt="cat2" />
                        <div className="about-caption">
                            <h3>Safu</h3>
                            <p>Sunpump launch</p>
                        </div>
                    </div>
                    <div className="about-image">
                        <img src={cat3} alt="cat3" />
                        <div className="about-caption">
                            <h3>Based devs</h3>
                            <p>Devs will push the token with callers</p>
                        </div>
                    </div>
                    <div className="about-image">
                        <img src={cat4} alt="cat4" />
                        <div className="about-caption">
                            <h3>Community</h3>
                            <p>Community will own the project</p>
                        </div>
                    </div>
                </div>
                <img ref={rightImageRef} className="right-image" src={hand} alt="cat4" />
            </div>
            <div className="about-border-bottom"></div>
        </section>
    );
};

export default AboutSection;
